<template>
  <div>
    <div @click="toggleExpand">
      <slot name="trigger" :expand="expand" :transitioning="transitioning" />
    </div>
    <transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <div v-show="expand" class="relative">
        <slot />
        <div
          class="absolute inset-x-0 bottom-0 h-[32px] bg-gradient-to-b from-[#ffffff00] to-[#ffffffff] pointer-events-none"
        ></div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  emits: ["onExpand", "onClose"],
  data() {
    return {
      expand: false,
      transitioning: false,
    };
  },

  methods: {
    toggleExpand() {
      this.expand = !this.expand;
    },
    enter(element: Element) {
      this.$emit("onExpand");
      this.transitioning = true;
      const el = element as HTMLElement;
      el.style.height = "auto";
      el.style.opacity = "0";
      const { height } = getComputedStyle(el);
      el.style.height = "0";

      // Force repaint to make sure the
      // animation is triggered correctly.

      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(el).height;

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        el.style.height = height;
        el.style.opacity = "1";
      });
    },
    leave(element: Element) {
      this.$emit("onClose");
      this.transitioning = true;
      const el = element as HTMLElement;
      const { height } = getComputedStyle(el);
      el.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(el).height;

      requestAnimationFrame(() => {
        el.style.height = "0";
        el.style.opacity = "0";
      });
    },
    afterEnter(element: Element) {
      this.transitioning = false;
    },
    afterLeave(element: Element) {
      this.transitioning = false;
    },

    close() {
      this.expand = false;
    },
  },
});
</script>
<style scoped>
.expand-enter-active {
  transition: height 600ms ease-in-out, opacity 400ms 200ms ease-in-out;
  overflow: hidden;
}
.expand-leave-active {
  transition: height 600ms ease-in-out, opacity 400ms ease-in-out;
  overflow: hidden;
}
.expand-enter,
.expand-leave-to {
  height: 0;
  opacity: 0;
}
</style>
