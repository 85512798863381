<template>
  <div ref="wrapper" :class="['w-full h-full', $props.class]">
    <div ref="root" class="w-full h-full translate-x-1/2 translate-y-1/2">
      <div
        :style="{
          transform: `translateX(${x}px) translateY(${y}px)`,
        }"
      >
        <div
          ref="sphere"
          class="w-[50px] h-[50px] rounded-full blur-[100px] -translate-x-1/2 -translate-y-1/2 opacity-50"
          :style="{
            backgroundColor: color,
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useElementBounding } from "@vueuse/core";
import { onBeforeUnmount, onMounted, ref, watch } from "vue";

withDefaults(
  defineProps<{
    class?: string;
    color?: string;
  }>(),
  {
    color: "#F215E9",
    class: "",
  }
);

const wrapper = ref(null as unknown as HTMLDivElement);

const bounding = useElementBounding(wrapper);

const playing = ref(false);
const x = ref(0);
const y = ref(0);
const direction = ref(Math.random() * 2 * Math.PI);
const prevTimestamp = ref<number | null>(null);

watch(bounding.width, (value, oldValue) => {
  if (oldValue === 0) {
    x.value = value * 0.5 - 125 - Math.random() * (value - 250);
  }
});

function step(timestamp: number) {
  if (!playing.value) {
    return;
  }

  const elapsed = timestamp - (prevTimestamp.value ?? timestamp);
  prevTimestamp.value = timestamp;

  const dx = 0.05 * elapsed * Math.sin(direction.value);
  const dy = 0.05 * elapsed * Math.cos(direction.value);

  if (
    (x.value + dx < bounding.width.value / -2 + 125 && dx < 0) ||
    (y.value + dy < bounding.height.value / -2 && dy < 0) ||
    (x.value + dx > bounding.width.value / 2 - 125 && dx > 0) ||
    (y.value + dy > bounding.height.value / 2 && dy > 0)
  ) {
    direction.value = Math.random() * Math.PI * 2;
  } else {
    x.value += dx;
  }

  y.value += dy;

  window.requestAnimationFrame(step);
}

function play() {
  playing.value = true;
  prevTimestamp.value = null;
  window.requestAnimationFrame(step);
}

onMounted(() => {
  play();
});

onBeforeUnmount(() => {
  playing.value = false;
});
</script>

<style scoped>
* {
  @apply !pointer-events-none;
}
</style>
