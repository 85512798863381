<template>
  <v-expand
    v-for="({ question, answer }, index) in items"
    :key="index"
    ref="faqs"
    class="relative border-b border-gray-300 overflow-hidden"
    @on-expand="onExpand(index)"
  >
    <template #trigger="{ expand, transitioning }">
      <button
        class="flex w-full items-center justify-between text-xs font-medium cursor-pointer px-5 text-left"
        type="button"
      >
        <span
          class="mr-6 text-lg leading-6 py-[27px] font-[670] -tracking-[0.005em]"
          >{{ question }}</span
        >
        <span
          class="transform transition origin-center duration-300 ease-in-out flex-shrink-0"
          :class="{ 'rotate-45': expand }"
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.25 1V9.5"
              stroke="#3726F4"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.5 5.25L1 5.25"
              stroke="#3726F4"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </button>
      <Transition>
        <OneSphere
          v-if="transitioning || expand"
          v-show="expand && !transitioning"
          class="absolute inset-0 pointer-events-none z-50"
        />
      </Transition>
    </template>
    <template #default>
      <p
        class="font-[570] pr-[60px] pl-5 pb-8 d rounded-xl text-sm leading-[23px] max-w-[605px]"
      >
        {{ answer }}
      </p>
    </template>
  </v-expand>
</template>

<script setup lang="ts">
import { ref } from "vue";
import OneSphere from "./OneSphere.vue";
import VExpand from "./VExpand.vue";

const faqs = ref([]);

const items: { question: string; answer: string }[] = [
  {
    question: "How to start selling subscriptions with Juo?",
    answer:
      "Install Juo and it will automatically integrate with your store. Then, set up your subscription offer, no-code add the Juo subscription widget and the link to the Customer Portal.",
  },

  {
    question: "Which ecommerce platforms is Juo compatible with?",
    answer:
      "As of now, Juo natively integrates with Shopify and Shopify Plus. Please let us know if you need a custom integration for other platforms or architectures, such as headless ecommerce.",
  },

  {
    question: "How is Juo different from other solutions?",
    answer:
      "Juo gives you the ultimate flexibility to build every subscription offer you need. It’s safe for your store’s performance, easy to use by your customer support, and built to provide delightful, on-brand subscriber experience.",
  },

  {
    question: "I already have subscriptions, can I migrate to Juo?",
    answer:
      "Of course. Our migration environment is built to handle most of the current subscription solutions. The migration process is safe, lossless and taken care of entirely by our engineering team.",
  },
];

function onExpand(index: number) {
  faqs.value.forEach((item, currentIndex) => {
    if (index != currentIndex) {
      // @ts-expect-error Untyped
      item.close();
    }
  });
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
